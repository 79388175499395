import { motion } from "framer-motion";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import type { TeamObject } from "../../_types";
import TeamCard from "../TeamCard/TeamCard";
import TeamCarousel from "./components/TeamCarousel/TeamCarousel";
import "./style.scss";

type Props = {
  data: TeamObject[];
  selected: TeamObject;
  onSelect: (v: number | undefined) => void;
};

const TeamDetails = ({ data, selected, onSelect }: Props) => {
  return (
    <motion.div
      id="team-details"
      transition={{ duration: 1 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <div className="top">
        <TeamCard data={selected} onClose={() => onSelect(undefined)} variant="details" />
      </div>
      <div className="divider"></div>
      <TeamCarousel
        data={data.filter((item) => item.id !== selected.id)}
        onSelect={onSelect}
      />
    </motion.div>
  );
};

export default TeamDetails;
